<template>
  <div>
    <main-body-header title="زمین های متخصصان"/>
    <left-body-ads class="body-wrapper h-100">
      <advicerSelectedFarms />
    </left-body-ads>
  </div>
</template>

<script>
import advicerSelectedFarms from "./subForm/advicerSelectedFarms";
import {mapActions} from "vuex";
import MainBodyHeader from "../../../components/mainHeader.vue";
import LeftBodyAds from "../../../components/left-body-ads.vue";

export default {
  components: {
    LeftBodyAds, MainBodyHeader,
    advicerSelectedFarms,
  },
  data() {
    return {
      advicerId: 0,
      advicerList: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionLegalEntity", ["GetAllAdvicers"]),
  },
  mounted() {},
  created() {},
};
</script>


