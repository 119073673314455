<template>
  <div class="">
    <CCallout color="info">
      <strong class="h6 mb-3">لیست زمین های در دسترس متخصص</strong>
      <v-select
        class="bg-white"
        dir="rtl"
        v-model="adviserSelected"
        :options="advicerList"
        label="fullName"
        placeholder="متخصص را انتخاب کنید"
      ></v-select>
    </CCallout>
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="farm-list-box p-3">
      <div
        class="d-grid grid-c-3 gap-20"
        v-if="advicerFarmList.length > 0"
      >
        <general-farm-card v-for="(item, index) in advicerFarmList" :key="index" :item="item">
          <div class="position-absolute top-0 left-0 m-2">
            <CButton
              v-if="item.access"
              color="danger"
              class="y-center-g-5"
              size="sm"
              @click="DeleteAdvicerFarm(item.id, item.title)"
            >
              <font-awesome-icon icon="minus"/>
              <span>حذف دسترسی</span>
            </CButton>
            <CButton
              v-else
              color="secondary"
              class="y-center-g-5"
              size="sm"
              @click="AddAdvicerFarm(item.farmId, item.title)"
            >
              <font-awesome-icon icon="plus"/>
              <span>افزودن زمین به متخصص</span>
            </CButton>
          </div>
        </general-farm-card>
      </div>
      <div v-else-if="!loading">
        <no-data message="متخصص هیچ زمینی ندارد" :show="!loading" v-if="advicerId > 0"/>
        <no-data v-else :show="!loading" message="متخصص را از لیست بالا انتخاب کنید"/>
      </div>
    </div>
  </div>
</template>

<script>
import {apiUrlRoot} from "../../../../constants/config";
import {mapActions} from "vuex";
import GeneralFarmCard from "../../../../components/generalFarmCard.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import NoData from "../../../../components/no-data.vue";

export default {
  name: "advicerSelectedAllFarms",
  components: {NoData, GeneralFarmCard,vSelect},
  data() {
    return {
      modelShowState: false,
      modalTitle: "",
      sumAreas: 0,
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      advicerId: 0,
      adviserSelected: "",
      advicerList: [],
      advicerFarmList: [],
      rootPat: apiUrlRoot,
    };
  },
  watch: {
    advicerId: function () {
      this.GetAllAdvicerFarms();
    },
    adviserSelected: function () {
      this.advicerId = this.adviserSelected.advicerId;
    },
  },
  methods: {
    ...mapActions("connectionFarmerAdvicer", [
      "GetAllFarmerAdvicer",
      "FarmerAdvicerFarms",
      "AdvicerDeleteFarm",
      "AdvicerAddFarm",
    ]),
    async GetAllAdvicer() {
      this.advicerId = 0;
      let result = await this.GetAllFarmerAdvicer({
        searchName: "",
      });
      this.advicerList = result.data.map((item) => {
        return {
          advicerId: item.advicerId,
          fullName: `${item.fullName} (${item.phone})`,
        };
      });
    },
    async GetAllAdvicerFarms() {
      this.advicerFarmList = [];
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let accessibleItems = await this.FarmerAdvicerFarms({
        advicerId: this.advicerId,
        canAdd: true,
      });
      let inAccessibleItems = await this.FarmerAdvicerFarms({
        advicerId: this.advicerId,
        canAdd: false,
      });
      console.log({accessibleItems,inAccessibleItems})
      this.advicerFarmList = [...accessibleItems.data.map((v)=>({...v,access:false})),...inAccessibleItems.data.map((v)=>({...v,access:true}))];
      this.advicerFarmList.forEach((element) => {
        element.image = `${apiUrlRoot}Farm/${element.image}`;
        element.productImage = `${apiUrlRoot}productImage/${element.productImage}`;
      });
      this.loading = false;
    },
    async AddAdvicerFarm(farmId, farmTitle) {
      this.$confirm({
        message: `زمین ${farmTitle} را به متخصص می دهید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال ثبت اطلاعات...";
            let result = await this.AdvicerAddFarm({
              advicerId: this.advicerId,
              farmId: farmId,
            });
            if (result.succeeded) {
              await this.GetAllAdvicerFarms();
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message} <br /><br />`,
                type: "info",
              });
            }
            this.loading = false;
          }
        },
      });
    },
    async DeleteAdvicerFarm(id, farmTitle) {
      this.$confirm({
        message: `زمین ${farmTitle} را از دسترس متخصص خارج میکنید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال ثبت اطلاعات...";
            let result = await this.AdvicerDeleteFarm({
              id: id,
            });
            if (result.succeeded) {
              this.GetAllAdvicerFarms();
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message} <br /><br />`,
                type: "info",
              });
            }
            this.loading = false;
          }
        },
      });
    },
    getImagePath(fileName, path) {
      return `${apiUrlRoot}${path}/${fileName}`;
    },
  },
  mounted() {
    this.GetAllAdvicer();
  },
};
</script>

<style scoped>
.farm-list-box {
  overflow-x: hidden;
  overflow-y: auto;
}

.bodyItem {
  float: right;
  clear: both;
  padding: 6px;
  min-height: 25vh;
}

.bodyItemValue {
  float: right;
  clear: both;
  border-right: 1px solid #a196fb;
  margin-top: 3px;
  padding-right: 6px;
  padding-top: 9px;
  background-color: #efefef;
  width: 100%;
}

.userList {
  width: 13vw;
  border: none;
  border-bottom: 1px solid darkgreen;
  color: darkgreen;
  outline: none;
}

.farmItem {
  width: 18vw;
  float: right;
  border-radius: 2em;
  background-color: #fff;
  margin: 1em;
  border: 1px solid #bbb;
  cursor: pointer;
  box-shadow: 0px 3px 10px -5px #000;
  position: relative;
  height: 37vh;
}

.farmItem .farmImg {
  float: right;
  width: 90%;
  height: 20vh;
  margin: 5%;
}

.farmItem .productImg {
  width: 10%;
  position: absolute;
  bottom: 10em;
  left: 2em;
}

.farmItem ul {
  float: right;
  margin: 0em;
  width: 100%;
  list-style: none;
  padding: 0em 0.5em;
}

.farmItem ul li {
  float: right;
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid #03c503;
  margin: 1px 0px;
  padding-right: 0.4em;
  font-family: "Dirooz";
  color: black;
  font-size: 1em;
  border-radius: 0px 4px 4px 0px;
}

.farmItemBtn {
  width: 14.6vw;
  height: 2.5vh;
  text-align: center;
  font-size: 13px;
  padding: 1px 1px 0px 0px;
  border: none;
  color: rgb(255 255 255);
  bottom: -1%;
  position: absolute;
  background-color: #c50303;
  right: 2em;
  border-radius: 0px 0px 10px 10px;
  transition: all 500ms;
}

.farmItemBtn:hover {
  box-shadow: 0px 7px 16px -4px #000;
}

.emptyBox h6 {
  font-size: 2em;
  color: #ffc037;
  margin-top: 10em;
  margin-right: 7em;
  font-family: inherit;
}
</style>
